
















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.exhibitionDetail {
    ::v-deep .ivu-input {
        height: 0.5rem !important;

        @media (max-width: 767px) {
            height: 0.5rem * 1.5 !important;
        }
    }

    .textArea ::v-deep .ivu-input {
        height: auto !important;

        @media (max-width: 767px) {
            height: auto !important;
        }
    }
}
