













































































































































.formContact {
    ::v-deep .ivu-input {
        border-radius: 0;
        height: 0.5rem !important;

        @media (max-width: 767px) {
            height: 0.5rem * 1.5 !important;
        }
    }

    .submit {
        width: 100%;
        display: flex;
        justify-content: center;
		margin-bottom: 0.4rem;

        @media (max-width: 767px) {
            justify-content: center;
        }

        .btn {
            margin-right: .1rem;
            width: 2rem;
            height: 0.5rem;

            @media (max-width: 767px) {
                width: 100%;
                height: 0.5rem * 1.5;
            }

            &.qx {
                background-color: #000000;
                border-color: #000000;
            }

            &.tj {
                background-color: #ff1932;
                border-color: #ff1932;
            }
        }
    }

    .tips {
        line-height: 1.5em;
    }

    .last {
        margin-bottom: 0 !important;
    }
}
