

















































































.zoom{
    position: fixed;
    z-index: 999999;
    top:0;
    left:0;
    width:100%;
    height: 100%;
    background-color: rgba(0,0,0,.6);
    display: flex;
    justify-content: center;
    align-items: center;
    &_box{
        width: 50vw;
        height: 67.6vh;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        &_close{
            position: absolute;
            top:0;
            right:-.6rem;
            width: .5rem;
            height: .5rem;
            cursor: pointer;
            background-color: #fff;
            border-radius: 50%;
            padding: .04rem;
            .img{
                width: 100%;
                &:first-child{
                    margin-right: .4rem;
                }
            }
        }
        &_con{
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .img{
                max-width: 100%;
                max-height: 100%;
            }
        }
        // 按钮
        &_btns{
            position: absolute;
            bottom:-.6rem;
            left:50%;
            margin-left:-.5rem;
            .img{
                width: .4rem;
                cursor: pointer;
                &:first-child{
                    margin-right: .2rem;
                }
                &.disabled{
                    opacity: .3;
                    cursor: not-allowed;
                }
            }
        }
        &.m{
            width: 90vw;
            height: 40vh;
            .zoom_box_close{
                top:.2rem;
                right:-.3rem;
                width: .8rem;
                height: .8rem;
            }
            .zoom_box_btns{
                margin-left: -1rem;
                width: 2rem;
                display: flex;
                justify-content: space-between;
                .img{
                    width: .8rem;
                    height: .8rem;
                }
            }   
        }
    }
}
